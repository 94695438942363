<template>
  <div class="default-modal login-modal">
    <div class="default-modal__header default-modal__header--center">
      <button type="button" class="default-modal__back" @click="$emit('close')">
        <IconComponent name="arrow-left-2" />
      </button>
      <h3 class="default-modal__title">Регистрация</h3>
      <button type="button" class="default-modal__close" @click="$emit('closeAll')">
        <IconComponent name="close-square" />
      </button>
    </div>
    <div class="default-modal__body login-modal__body">
      <div class="login-modal__inputs">
        <InputComponent
          v-model="form.email.value"
          :error="form.email.error"
          title="Email"
          placeholder="Электронная почта"
          modified
          type="email"
        />
        <InputComponent
          v-model="form.password.value"
          :error="form.password.error"
          type="password"
          title="Пароль"
          placeholder="Ваш пароль"
          modified
        />
        <InputComponent
          v-model="form.password_confirmation.value"
          :error="form.password_confirmation.error"
          type="password"
          title="Подтвердите пароль"
          placeholder="Ваш пароль"
          modified
        />
      </div>
    </div>
    <div class="default-modal__footer login-modal__footer">
      <button type="button" class="btn btn--md btn--main" @click="submit">
        <IconComponent v-if="loading" name="loading" />
        <template v-else>Зарегистрироваться</template>
      </button>
    </div>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import IconComponent from "components/IconComponent.vue";
import AlertModal from "components/modals/components/AlertModal.vue";
import USER_REGISTER from "gql/auth/UserRegister.graphql";

export default {
  name: "RegisterModal",
  data() {
    return {
      number: null,
      loading: false,
      error: null,
      codeError: null,
      form: {
        name: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        email: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        password: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        password_confirmation: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
      },
    };
  },
  methods: {
    submit() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: USER_REGISTER,
            variables: {
              name: this.form.name.value,
              email: this.form.email.value,
              password: this.form.password.value,
              password_confirmation: this.form.password_confirmation.value,
            },
          })
          .then(({ data }) => {
            if (data && data.UserRegister) {
              this.showAlertModal({ title: "Регистрация", message: data.UserRegister.message });
            }
            this.loading = false;
          })
          .catch(({ graphQLErrors }) => {
            this.loading = false;
            this.parseGqlErrors(graphQLErrors);
          });
      }
    },
    showAlertModal(options) {
      this.$store.state._modals.push({
        component: AlertModal,
        options: options,
      });
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].error = err.extensions.validation[key][0];
            }
          });
        }
      });
    },
  },
  components: { IconComponent, InputComponent },
};
</script>

<style lang="stylus">
.login-modal {
  width 100%
  max-width 382px

  &__inputs {
    width 100%
    display grid
    grid-gap 20px

    .input {
      width 100%

      &__container {
        border-color var(--gray-3-dark)
      }

      &__field {
        font-size: 1.125em
        line-height: 20px;
      }
    }
  }

  &__input {
    display grid
    grid-gap 5px
    width 100%
  }

  &__inputs-title {
    display flex
    align-items center
    gap 8px
    width 100%
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    color: var(--body-color-muted);
  }

  &__body > .input {
    width 100%
  }

  &__footer .btn {
    width 100%
    justify-content center
  }
}
</style>
