<template>
  <a href="https://comrades.dev" target="_blank" class="comrades">
    <IconComponent name="comrades-logo" />
    <div class="comrades-text">
      <span class="comrades-text__title">Разработано</span>
      <span class="comrades-text__subtitle">COMRADES DEVS</span>
    </div>
  </a>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
export default {
  name: "ComradesLogo",
  components: { IconComponent },
};
</script>

<style lang="stylus">
.comrades {
  display flex
  gap 15px
  align-items center
  --blue: #132F63

  .icon {
    width: 46px
    height 46px
  }

  &-text {
    display flex
    flex-direction column
    color var(--blue)
    gap 5px

    &__title {
      font-size 0.75em
      line-height 14px
    }

    &__subtitle {
      font-weight 600
      font-size 0.875em
      line-height 16px
    }
  }
}
</style>
