<template>
  <div v-if="brands && brands.length" class="brands">
    <h2 class="brands__title" v-if="title">{{ title }}</h2>
    <SliderComponent class="brands__slider" :items="brands" v-slot="{ item }" :slider-options="sliderOptions">
      <BrandComponent :data="item" />
    </SliderComponent>
  </div>
</template>

<script>
import BrandComponent from "components/brands/BrandComponent.vue";
import SliderComponent from "components/SliderComponent.vue";

export default {
  name: "BrandsComponent",
  components: { SliderComponent, BrandComponent },
  props: {
    brands: Array,
    title: String,
  },
  data() {
    return {
      sliderOptions: {
        slidesPerView: 2,
        spaceBetween: 15,
        pagination: false,
        clickable: false,
        preloadImages: true,
        loop: true,
        breakpoints: {
          640: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          960: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: 5,
            spaceBetween: 40,
          },
        },
      },
    };
  },
};
</script>
