import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

Vue.use(Vuex);
export default function createStore(env) {
  return new Vuex.Store({
    actions,
    mutations,
    getters,
    state() {
      return {
        _env: env,
        _modals: [],
        _menus: [],
        _overlays: {
          searchOverlay: false,
          mobileSearchOverlay: false,
          categoriesOverlay: false,
        },
        _status: {
          // коды заказов
          CODE_ORDER_CREATED: 1, // Создан ожидает оплаты
          CODE_ORDER_SUCCESS: 2, // оплачено обработка заказа
          CODE_ORDER_CANCELED: 3, // отмена оплаты
          CODE_ORDER_FAILED: 4, // оплата провалена
          CODE_ORDER_IN_PROCESS: 5, // обработан ожидает отправки
          CODE_ORDER_IN_DELIVERY: 6, // в службе доставки
          CODE_ORDER_DELIVERED: 7, // в службе доставки
          CODE_ORDER_OFFLINE_PAY: 8, // оплата при получении
        },
        _types: {
          // способы доставки
          PICKUP: 1, // Самовывоз
          COURIER_DELIVERY: 2, // Курьерская доставка
          EXPRESS_DELIVERY: 3, // Экспресс доставка
          POST_DELIVERY: 4, // Почта России
          // способы оплаты
          ONLINE_CARD_PAY: 1, // Оплата картой онлайн
          OFFLINE_CARD_PAY: 2, // Картой при получении
          OFFLINE_CASH_PAY: 3, // Наличными при получении
          // коды контактных данных
          ADDRESS: 1,
          PHONE: 2,
          SOCIAL: 3,
          EMAIL: 4,
          // коды баннеров
          BANNER_SLIDER: 1,
          BANNER_HEALTHY_PRODUCTS: 2, // баннер в блоке "КЛЮЧ К ЗДОРОВОМУ КОМФОРТУ"
          BANNER_UNDER_HEALTHY_PRODUCTS: 3, // баннер под блоком "КЛЮЧ К ЗДОРОВОМУ КОМФОРТУ"
          BANNER_FASHION: 4, // баннер в блоке "СТИЛЬНО, МОДНО И КОМФОРТНО"
          BANNER_PRODUCTS_LISTS: 5, // баннеры в спике товаров
          // коды заявок
          ORTHOPEDIST: 1, // запись к ортопеду
          REFERRAL: 2, // загрузить направление
          HAPPY: 3, // рады помочь
          // коды страниц
          PAYMENT_METHODS: 1, // способы оплаты
          ORDERING: 2, // как оформить заказ
          REFUND: 3, // возврат
          HELP: 4, // помощь
        },
        variables: {}, // данные для фильтра
        // дефолтные глобальные данные
        global: {
          header_categories: [], // категории для вывода в хедере под поиском
          footer_categories: [], // категории для вывода в футере
          categories: [], // главные категории
          brands: [],
          info: {}, // информация о магазине, контакты
          pages: [], // страницы
          branches: [],
        },
        auth: {
          user: null,
          cart: [], // товары в корзине
          orders: [], // все заказы, чтобы подсчитать сумму
          orders_paginate: {}, // заказы в лк
          deliveries: [], // способы доставки
          favorites: [], // все избранные, чтобы вывести в хедере
          favorites_ids: [], // id всех избранных товаров
          // favorites_paginate: {}, // избранные в лк
          addresses: [], // адреса доставки
        },
        home_page: {
          banners: [],
          popular_categories: [],
          lead_magnet_1: [],
          lead_magnet_2: [],
          banner_healthy_products: [],
          banner_under_healthy_products: [],
          banner_fashion: [],
          shares: [],
        },
        product_page: {
          product: {},
        },
        catalog_page: {
          products: {},
          banners: [],
          categories: [],
          category: {},
          shares: [],
          filter_attributes: [],
          brand: {},
          products_min_max_price: {},
        },
        shops_page: {
          shops: [],
        },
        shares_page: {
          shares: {},
        },
        share_page: {
          share: {},
          products: {},
          // banners
        },
        search_page: {
          products: {},
          banners: {},
          banners_wide: {},
        },
        static_page: {},
        page_404: {
          products: [],
        },
      };
    },
  });
}
