<template>
  <div
    class="header-dropdown"
    :class="{ 'header-dropdown--visible': $store.state._overlays.categoriesOverlay }"
  >
    <div class="container-padding">
      <div class="header-dropdown__inner">
        <div class="header-dropdown__categories-wrap">
          <ul class="header-dropdown__categories">
            <li
              v-for="item in categories"
              :key="item.id"
              @mouseenter="active = item"
              class="header-dropdown__categories-item"
            >
              <router-link
                :to="{ name: 'catalog', params: { id: item.id } }"
                class="header-dropdown__category"
                :class="{
                  'header-dropdown__category--active': JSON.stringify(active) === JSON.stringify(item),
                }"
              >
                <ImgComponent class="header-dropdown__category-img" :img="item.head_img" />
                <span>{{ item.title }}</span>
              </router-link>
            </li>
          </ul>
        </div>
        <ul v-if="active && active.childs && active.childs.length" class="header-dropdown__categories-childs">
          <li class="header-dropdown__categories-item" v-for="child in active.childs" :key="child.id">
            <router-link
              :to="{ name: 'catalog', params: { id: child.id } }"
              class="header-dropdown__category"
            >
              <span>{{ child.title }}</span>
              <IconComponent name="arrow-right-2" />
            </router-link>
          </li>
        </ul>
        <div class="header-dropdown__product">
          <!--          <ProductCardComponent :data="1" />-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import ProductCardComponent from "components/product/ProductCardComponent.vue";

export default {
  name: "HeaderComponent",
  components: { ProductCardComponent, IconComponent },
  data() {
    return {
      active: undefined,
    };
  },
  mounted() {
    if (this.categories && this.categories.length) {
      this.active = this.categories[0];
    }
  },
  computed: {
    categories() {
      return this.$store.state.global.categories;
    },
  },
};
</script>

<style lang="stylus">
.header-dropdown {
  padding 20px 0
  background var(--gray)
  position: absolute;
  left 0
  right 0
  top 100%
  z-index 1
  visibility hidden
  opacity: 0;
  transition var(--transition)
  border-top: 1px solid var(--border-color);
  box-shadow: 0 40px 50px rgba(0, 0, 0, 0.15);
  +below(1025px) {
    display none
  }

  &--visible {
    visibility visible
    opacity 1
  }

  &__inner {
    display grid
    grid-template-columns minmax(300px, 1fr) 2fr minmax(240px, 1fr)
    grid-gap var(--gap)
    align-items flex-start
  }

  &__categories {
    display flex
    flex-direction column
    gap: 5px
    max-height 680px
    overflow auto

    &-wrap {
      padding 10px
      background var(--white)
      border-radius var(--main-radius)
    }

    &-item {
      flex-shrink 0
    }
  }

  &__categories-childs {
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 5px var(--gap)
    max-height 700px
    overflow-y auto
    +below(1300px) {
      grid-template-columns 1fr
    }

    & ^[0]__category {
      padding 10px
      justify-content space-between
      font-size 1em
      line-height: 26px;

      &:hover {
        background var(--white)

        .icon svg path {
          fill var(--main)
        }
      }

      .icon {
        width 16px
        height 16px

        svg path {
          fill var(--dark-light)
        }
      }
    }
  }

  &__category {
    display flex
    align-items center
    gap: 10px
    padding 5px
    border-radius var(--main-radius)
    font-size: 1.125em
    line-height: 28px;
    background var(--white)

    &--active,
    &:hover {
      background var(--gray)
      color var(--main)
    }

    &-img.img {
      flex-shrink 0
      width 40px
      height 40px
      border: 2px solid var(--white)
      border-radius: var(--small-radius)
    }
  }
}
</style>
