<template>
  <div class="header-bottom">
    <div class="header-bottom__container container-padding">
      <ul class="header-bottom__list">
        <li>
          <router-link :to="{ name: 'shares' }" class="header-bottom__link"> Акции </router-link>
        </li>
        <li v-for="(item, i) in categories" :key="i">
          <router-link :to="{ name: 'catalog', params: { id: item.id } }" class="header-bottom__link">
            {{ item.title }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderBottomComponent",
  props: {
    activeId: Number,
  },
  computed: {
    categories() {
      return this.$store.state.global.header_categories || [];
    },
  },
};
</script>

<style lang="stylus">
.header-bottom {
  display flex
  justify-content center
  align-items center
  position relative
  padding-bottom 24px
  +below(1100px) {
    display none
  }

  &__container {
    display inline-flex
    align-items center
  }

  &__list {
    display inline-flex
    align-items center
    gap: 10px
    flex-shrink 0
    width 100%
    overflow-x auto
    position relative

    li {
      flex-shrink 0
      display flex
      align-items center
    }
  }

  &__link {
    padding 4px 9px
    display inline-flex
    align-items center
    font-size: 0.875em
    line-height: 20px;
    transition var(--transition)
    border 1px solid var(--border-color)
    border-radius var(--small-radius)

    &:hover {
      border-color var(--main)
    }
  }
}
</style>
