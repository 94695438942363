<template>
  <div id="app">
    <vue-progress-bar />
    <HeaderComponent />
    <main class="main">
      <Transition mode="out-in" name="page">
        <RouterView :key="$route.fullPath" />
      </Transition>
    </main>
    <FooterComponent />
    <div @click="hideOverlay" :class="{ 'overlay--visible': isOverlayVisible }" class="overlay"></div>
    <Transition name="slide">
      <MenuComponent />
    </Transition>
    <Transition name="bounce">
      <ModalComponent />
    </Transition>
    <Notifications position="bottom right" />
    <CookiesComponent />
  </div>
</template>

<script>
import ModalComponent from "components/modals/index.vue";
import HeaderComponent from "components/header/index.vue";
import FooterComponent from "components/footer/index.vue";
import SubscribeComponent from "components/SubscribeComponent.vue";
import MenuComponent from "components/menus/index.vue";
import CookiesComponent from "components/CookiesComponent.vue";

export default {
  name: "App",
  created() {
    // Полоска загрузки
    this.$Progress.start();
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress;
        this.$Progress.parseMeta(meta);
      }
      this.$Progress.start();
      next();
    });
    this.$router.afterEach(() => {
      this.$Progress.finish();
      this.hideOverlay();
    });
  },
  mounted() {
    this.$Progress.finish();
    // если пользователь не авторизован, то берем товары в корзине из localStorage
    if (!this.$store.state.apollo_token) {
      this.$store.state.auth.cart = localStorage.cartItems
        ? JSON.parse(localStorage.cartItems).filter((c) => c.item)
        : [];
    }
  },
  watch: {
    "$store.state.auth.cart"() {
      // обновляем localStorage при каждом изменении state.auth.cart
      localStorage.cartItems = this.$store.state.auth.cart ? JSON.stringify(this.$store.state.auth.cart) : [];
    },
    "$route.name"() {
      this.$store.state.variables = {};
    },
    "$route.params"() {
      this.$store.state.variables = {};
    },
  },
  computed: {
    isOverlayVisible() {
      return Object.keys(this.$store.state._overlays).some((k) => this.$store.state._overlays[k]);
    },
  },
  methods: {
    hideOverlay() {
      Object.keys(this.$store.state._overlays).forEach((key) => {
        this.$store.state._overlays[key] = false;
      });
    },
  },
  components: {
    CookiesComponent,
    MenuComponent,
    SubscribeComponent,
    ModalComponent,
    HeaderComponent,
    FooterComponent,
  },
  metaInfo: {
    title: "Ортосити - сеть ортопедических салонов",
    titleTemplate: "%s | Ортосити - сеть ортопедических салонов",
    meta: [
      {
        vmid: "keywords",
        name: "keywords",
        content:
          "ортопедические товары, купить ортопедические товары, магазин ортопедических товаров, ортопедические товары для спины, матрасы ортопедические, подушки ортопедические, ортопедические стельки, корректоры осанки, товары для здоровой спины, товары для здоровья суставов, товары для реабилитации после травмы, ортопедические изделия, ортопедическое белье, лечебная обувь, ортопедические товары для детей",
      },
      {
        vmid: "description",
        name: "description",
        content:
          "На сегодняшний день компания «ОРТОСИТИ» является крупнейшим поставщиком на территории республик СКФО ортопедических изделий, средств реабилитации, медицинского расходного материала, а также средств по уходу за больными для ортопедических салонов, аптек, и ЛПУ. Компания помогает реализовывать государственные проекты помощи инвалидам. Участвует в благотворительных акциях, направленных на реализацию программ помощи инвалидам.",
      },
      {
        vmid: "og:title",
        name: "og:title",
        content: "Ортосити - сеть ортопедических салонов",
      },
      {
        vmid: "og:description",
        name: "og:description",
        content:
          "На сегодняшний день компания «ОРТОСИТИ» является крупнейшим поставщиком на территории республик СКФО ортопедических изделий, средств реабилитации, медицинского расходного материала, а также средств по уходу за больными для ортопедических салонов, аптек, и ЛПУ. Компания помогает реализовывать государственные проекты помощи инвалидам. Участвует в благотворительных акциях, направленных на реализацию программ помощи инвалидам.",
      },
      {
        vmid: "og:type",
        name: "og:type",
        content: "website",
      },
    ],
  },
};
</script>

<style lang="stylus">
@import 'styles/style.styl';
</style>
