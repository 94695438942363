<template>
  <header
    :class="{
      'header--categories-overlay': $store.state._overlays.categoriesOverlay,
      'header--search-overlay': $store.state._overlays.searchOverlay,
      'header--mobile-search-overlay': $store.state._overlays.mobileSearchOverlay,
      'header--sticky': sticky,
    }"
    class="header"
  >
    <HeaderTopComponent ref="headerTop" />
    <HeaderMainComponent
      @search="searchInput"
      :loading="loading"
      :products="products"
      :total="total"
      :cart-items-length="cartItemsLength"
    />
    <HeaderBottomComponent />
    <!--    <HeaderSearchComponent @search="searchInput" :loading="loading" :products="products" :total="total" />-->
  </header>
</template>

<script>
import HeaderTopComponent from "components/header/components/HeaderTopComponent.vue";
import HeaderMainComponent from "components/header/components/HeaderMainComponent.vue";
import HeaderBottomComponent from "components/header/components/HeaderBottomComponent.vue";
import HeaderSearchComponent from "components/header/components/HeaderSearchComponent.vue";
import PRODUCTS_PAGINATE from "gql/queries/products_paginate.graphql";

export default {
  name: "HeaderComponent",
  data() {
    return {
      hoverActiveId: null,
      products: [],
      loading: false,
      total: undefined,
      sticky: false,
    };
  },
  mounted() {
    window.onscroll = this.makeHeaderFixed;
  },
  computed: {
    cartItemsLength() {
      return this.$store.state.auth.cart && this.$store.state.auth.cart.length
        ? this.$store.state.auth.cart.length
        : 0;
    },
  },
  methods: {
    makeHeaderFixed() {
      let offsetTop = this.$refs.headerTop?.$el.offsetHeight || 0;
      this.sticky = window.scrollY && window.scrollY >= offsetTop;
    },
    searchInput(title) {
      if (title) {
        if (this.inputDebounceTimeout) {
          clearTimeout(this.inputDebounceTimeout);
        }
        this.loading = true;
        this.inputDebounceTimeout = setTimeout(() => {
          this.submitSearch(title);
        }, 500);
      } else {
        this.products = [];
      }
    },
    async submitSearch(title) {
      await this.$apollo.provider.defaultClient
        .query({
          query: PRODUCTS_PAGINATE,
          variables: {
            title: title,
            parent: true,
            first: 12,
          },
        })
        .then(({ data }) => {
          this.loading = false;
          this.products = data.products_paginate.data;
          this.total = data.products_paginate.paginatorInfo?.total;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  components: {
    HeaderSearchComponent,
    HeaderBottomComponent,
    HeaderMainComponent,
    HeaderTopComponent,
  },
};
</script>

<style lang="stylus">
.header {
  z-index 11
  position relative
  background var(--gray)

  &--mobile-search-overlay,
  &--search-overlay {
    z-index auto

    .header-top,
    .header-main {
      position relative
      z-index 11
    }
  }

  &--sticky {
    padding-top var(--header-main-height)

    .header-main {
      fixed top left right
      z-index 100
      box-shadow 0 4px 15px var(--black-o1)
    }
  }

  &-search__input .input {
    &:focus-within + .search-dropdown {
      opacity 1
      visibility visible
      top 100%
    }
  }

}
</style>
