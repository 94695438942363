<template>
  <router-link
    :to="{
      name: 'product',
      params: { id: data.parent_id || data.id, link: data.link },
      query: { set_id: data.parent_id ? data.id : undefined },
    }"
    class="product-search no-hover"
    v-if="data"
  >
    <div class="product-search__image-container">
      <div class="ratio-container">
        <ImgComponent :img="data | item_img" class="product-search__image" />
      </div>
    </div>
    <div class="product-search__container">
      <div class="product-search__content">
        <span class="product-search__category" v-if="data.category && data.category.title">
          {{ data.category.title }}
        </span>
        <span v-if="data.title" :title="data.title" class="product-search__title">
          {{ data.title }}
        </span>
      </div>
      <div class="product-search__bottom">
        <div class="product-search__prices">
          <!--          <span class="product-search__bonus">-->
          <!--            <span class="product-search__bonus-price"> 855 ₽ </span>-->
          <!--            <span class="product-search__bonus-percent"> -5% с бонусной картой </span>-->
          <!--          </span>-->
          <span class="product-search__price">
            <span v-if="!allSame">от</span>
            <span v-if="data.product_sets && data.product_sets.length">
              {{ data | product_price | formatPrice }}
            </span>
            <span v-else>{{ data.price | share_price(data) | formatPrice }}</span>
          </span>
          <span v-if="data.join_share && data.join_share.share" class="product-search__old-price">
            {{ data.price | formatPrice }}
          </span>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "ProductCardSearchVersionComponent",
  props: {
    data: Object,
  },
  computed: {
    allSame() {
      let sets = this.data.product_sets || [];
      return sets.every((i) => i.price === sets[0].price);
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/maxlines.styl"
@import "~@/styles/mixins/ratio.styl"
.product-search {
  background: var(--white);
  border-radius: var(--main-radius);
  display inline-flex
  flex-direction column
  position relative
  border: 1px solid var(--gray);
  color: var(--dark)
  width 100%
  height: 100%
  cursor: pointer;
  overflow hidden
  transition var(--transition)

  &:hover {
    border-color var(--main)
  }

  &__image-container {
    position relative
    background: none;
    border: none;
    border-bottom 1px solid var(--gray)
    overflow hidden
    ratio(1, 1, contain)
  }

  &__image {
    height 100vh
    max-height 320px
    background-color var(--white)
  }

  &__container {
    display flex
    flex-direction column
    gap 10px
    flex-grow 1
    padding 10px
    +below(768px) {
      padding 5px
    }
  }

  &__content {
    display flex
    flex-direction column
    gap 5px
  }

  &__category {
    maxlines(2)
    font-size: 0.75em
    line-height: 14px;
    color: var(--dark-light);
  }

  &__title {
    font-weight 700
    height 78px
    maxlines(3)
    +below(540px) {
      font-size: 0.875em
      line-height: 18px;
      height 54px
    }
  }

  &__bottom {
    display flex
    flex-direction column
    gap 10px
    margin-top auto
  }

  &__bonus {
    width 100%
    display flex
    align-items center
    gap: 5px

    &-price {
      flex-shrink 0
      font-weight: 700;
      font-size: 1.125em;
      line-height: 1.55
      color: var(--white)
      background: var(--red)
      border-radius: var(--small-radius)
      padding 0 5px
    }

    &-percent {
      font-size: 0.75em
      line-height: 14px;
      color: var(--red);
    }
  }

  &__price {
    font-weight: 700;
    white-space nowrap
    color var(--dark-light)
    padding 0 5px
  }

  &__old-price {
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-decoration-line: line-through;
    text-decoration-color var(--red)
    color var(--dark-light)
    padding 0 7px
  }
}
</style>
