<template>
  <div v-if="data" class="contact-wrap">
    <a :href="link" class="contact" :title="data.title">
      <IconComponent :name="icon" />
      <span class="contact-value">{{ data.value }}</span>
    </a>
    <span class="contact-notice">{{ data.notice }}</span>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
export default {
  name: "ContactComponent",
  components: { IconComponent },
  props: {
    data: Object,
  },
  computed: {
    icon() {
      switch (this.data?.type?.code) {
        case this.$store.state._types.ADDRESS: {
          return "location";
        }
        case this.$store.state._types.PHONE: {
          return "call";
        }
        case this.$store.state._types.EMAIL: {
          return "sms";
        }
      }
    },
    link() {
      switch (this.data?.type?.code) {
        case this.$store.state._types.PHONE: {
          return this.$options.filters.phone(this.data.value);
        }
        case this.$store.state._types.EMAIL: {
          return `mailto:${this.data.value}`;
        }
        default: {
          return this.data.link;
        }
      }
    },
  },
};
</script>

<style lang="stylus">
.contact {
  display flex
  align-items center
  gap: 5px
  padding 5px 10px
  color inherit

  &-wrap {
    display flex
    flex-wrap wrap-reverse
    align-items center
  }

  &-notice {
    color: var(--dark-light);
  }

  .icon {
    width: 20px
    height: 20px
  }
}
</style>
