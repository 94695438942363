<template>
  <footer class="footer">
    <template v-if="showSections">
      <SectionComponent v-if="widget">
        <div class="footer__reviews" v-html="widget"></div>
      </SectionComponent>
      <SectionComponent>
        <h2 class="footer__map-title">
          ОРТОСИТИ - КРУПНЕЙШАЯ НА СЕВЕРНОМ КАВКАЗЕ СЕТЬ <br />
          ОРТОПЕДИЧЕСКИХ САЛОНОВ
        </h2>
        <AchievementsComponent />
        <MapLocationsComponent />
        <!--        <BrandsComponent :brands="brands" title="Реселлеры лидеров рынка вашего комфорта" />-->
      </SectionComponent>
    </template>
    <div class="footer-real">
      <SubscribeComponent />
      <MainFooterComponent />
      <SmallFooterComponent />
    </div>
  </footer>
</template>

<script>
import MainFooterComponent from "components/footer/components/MainFooterComponent.vue";
import SmallFooterComponent from "components/footer/components/SmallFooterComponent.vue";
import AchievementsComponent from "components/AchievementsComponent.vue";
import MapLocationsComponent from "components/MapLocationsComponent.vue";
import SectionComponent from "components/SectionComponent.vue";
import BrandsComponent from "components/brands/BrandsComponent.vue";
import SubscribeComponent from "components/SubscribeComponent.vue";

export default {
  name: "FooterComponent",
  components: {
    SubscribeComponent,
    BrandsComponent,
    SectionComponent,
    MapLocationsComponent,
    AchievementsComponent,
    SmallFooterComponent,
    MainFooterComponent,
  },
  computed: {
    widget() {
      return this.$store.state.global.info?.yandex_widget;
    },
    brands() {
      return this.$store.state.global.brands || [];
    },
    showSections() {
      return !["catalog"].includes(this.$route.name);
    },
  },
};
</script>

<style lang="stylus">
.footer {
  display: grid;
  grid-gap: var(--gap);

  .section:nth-of-type(2n-1) {
    padding 40px 0
    background var(--white)
    position relative
    z-index 1
    +below(540px) {
      padding 30px 0
    }
  }

  &__reviews {
    overflow auto

    &-block {
      min-width 770px
      width 100%
      height 100%
      object-fit contain
      object-position center
    }

    .sw-swiper-button-arrow-type2 {
      flex-shrink 0
    }
  }

  &__map-title {
    margin 0
    text-align center
    +below(440px) {
      font-size: 1.125em
      line-height: 28px;
    }
  }
}
</style>
