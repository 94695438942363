<template>
  <div
    class="search-dropdown"
    :class="{ 'search-dropdown--visible': $store.state._overlays.searchOverlay }"
    v-if="search"
  >
    <div class="container-padding">
      <div class="search-dropdown__inner">
        <span v-if="loading" class="search-dropdown__loading">
          <IconComponent name="loading" />
        </span>
        <template v-else-if="items && items.length">
          <div :style="{ opacity: loading ? 0.7 : 1 }" class="search-dropdown__items">
            <div v-for="(item, i) in items" :key="i" class="search-dropdown__item">
              <ProductCardSearchVersionComponent :data="item" />
            </div>
          </div>
          <router-link v-if="total > 12" :to="route" class="btn btn--md btn--blue no-hover">
            <span>Показать все {{ total }} {{ total | plural_word("товар", "товара", "товаров") }}</span>
          </router-link>
        </template>
        <span v-else>Ничего не найдено</span>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import ProductCardSearchVersionComponent from "components/product/ProductCardSearchVersionComponent.vue";

export default {
  name: "SearchResultsDropdownComponent",
  components: { ProductCardSearchVersionComponent, IconComponent },
  props: {
    items: Array,
    total: Number,
    loading: Boolean,
    search: String,
    route: {
      type: Object,
      default() {
        return {
          name: "search",
        };
      },
    },
  },
};
</script>

<style lang="stylus">
.search-dropdown {
  position absolute
  //top calc(100% + 10px)
  top 100%
  left 0
  right 0
  z-index 10
  background var(--white)
  border-radius 0 0 var(--main-radius) var(--main-radius)
  box-shadow: 0 10px 60px -15px rgba(0, 0, 0, 0.4);
  padding 20px 0
  opacity 0
  visibility hidden
  transition var(--transition)

  &--visible {
    visibility: visible
    opacity 1
  }

  &__inner {
    display flex
    flex-direction column
    align-items center
    gap: 20px
  }

  &__items {
    width: 100%
    max-height 65vh
    overflow-y auto
    transition var(--transition)
    display grid
    grid-template-columns repeat(6, 1fr)
    grid-gap 30px
    padding-right 5px
    +below(1350px) {
      grid-template-columns repeat(5, 1fr)
    }
    +below(1100px) {
      gap 20px
    }
    +below(1050px) {
      grid-template-columns repeat(4, 1fr)
    }
    +below(680px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(520px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(410px) {
      grid-template-columns 1fr
    }
  }

  &__loading {
    display flex

    .icon {
      width 60px
      height 60px
      +below(480px) {
        width 35px
        height 35px
      }

      svg circle {
        stroke var(--black)
      }
    }
  }
}
</style>
