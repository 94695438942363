import axios from "axios";
import AlertModal from "components/modals/components/AlertModal.vue";

export async function confirmUser({ store, apollo, data = {}, callback }) {
  store.state.auth.user = data.user;
  store.state.apollo_token = data.token;
  // Сохраняю токен в сессии
  await axios({
    method: "post",
    url: "/token",
    data: {
      token: data.token,
    },
    headers: {
      "Content-Type": "application/json",
    },
  }).then(() => {
    // сохраняем товары в корзине из store, куда они добавлются из localStorage в App.vue
    store
      .dispatch("CART_ADD_ITEMS", {
        apollo: apollo,
        variables: {
          items: store.state.auth.cart.map((c) => {
            return {
              id: c.item.id,
              count: c.count,
            };
          }),
        },
      })
      .then(() => {
        store.dispatch("ME", {
          apollo: apollo,
        });
      });
    if (callback) {
      callback();
    }
  });
}

/**
 * Выход из аккаунта
 * @param store
 * @param apollo
 * @returns {Promise<void>}
 */
export async function logout({ store, router }) {
  store.state._modals.push({
    component: AlertModal,
    options: {
      title: "Выйти из аккаунта",
      message: "Вы уверены, что хотите выйти из аккаунта?",
      isConfirm: true,
      closeBtnTitle: "Отмена",
      confirmBtnTitle: "Выйти",
      closeBtnClass: "btn--gray",
      confirmBtnClass: "btn--red",
      callback: () => {
        store.state.apollo_token = undefined;
        store.state.auth.user = null;
        axios({
          method: "post",
          url: "/token",
          data: {
            token: null,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }).then(() => {
          if (router.currentRoute.name === "user") {
            router.push({ name: "home" }).then(() => {
              location.reload();
            });
          } else {
            location.reload();
          }
        });
      },
    },
  });
}

/**
 * Удаление товаров из корзины
 * @param store
 * @param apollo
 * @param data - товар или элемент корзины с товаром, который надо удалить
 * @returns {Promise<void>}
 */
export async function removeCartItems({ store, apollo, ids = [] }) {
  let isRegistered = store.state.apollo_token || store.state.auth.user;
  store.state.auth.cart = store.state.auth.cart.filter((c) =>
    c.id ? !ids.includes(c.id) : !ids.includes(c.item.id)
  );
  if (isRegistered) {
    await store.dispatch("CART_DELETE_ITEMS", {
      apollo: apollo,
      variables: {
        id: ids,
      },
    });
  }
}
