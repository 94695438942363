<template>
  <div class="default-modal click-buy-modal">
    <div class="default-modal__header">
      <h3 class="default-modal__title">Купить в один клик</h3>
      <button type="button" class="default-modal__close" @click="$emit('close')">
        <IconComponent name="close-square" />
      </button>
    </div>
    <div class="default-modal__body click-buy-modal__body">
      <span class="click-buy-modal__text">
        Оставьте свой номер телефона. Вам перезвонит наш специалист и уточнит детали заказа.
      </span>
      <InputComponent
        v-model="number"
        :error="error"
        title="Номер телефона"
        placeholder="+7 (___) ___ - __ - __"
        mask="+7 (###) ### - ## - ##"
        modified
        is-phone
        @enter="submit"
      />
    </div>
    <div class="default-modal__footer click-buy-modal__footer">
      <button type="button" class="btn btn--md btn--main" @click="submit">
        <IconComponent v-if="loading" name="loading" />
        <template v-else>Оформить заказ</template>
      </button>
    </div>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import IconComponent from "components/IconComponent.vue";
import AlertModal from "components/modals/components/AlertModal.vue";
import ONE_CLICK_PURCHASE_CREATE from "gql/mutations/OneClickPurchaseCreate.graphql";

export default {
  name: "ClickBuyModal",
  props: {
    product: Number,
  },
  data() {
    return {
      number: null,
      loading: false,
      error: null,
    };
  },
  methods: {
    submit() {
      if (!this.loading) {
        if (this.number) {
          this.loading = true;
          this.error = null;
          this.$apollo.provider.defaultClient
            .mutate({
              mutation: ONE_CLICK_PURCHASE_CREATE,
              variables: {
                phone: this.number || undefined,
                product: this.product,
              },
            })
            .then(({ data }) => {
              this.loading = false;
              if (data && data.OneClickPurchaseCreate) {
                this.showAlertModal({
                  title: "Купить в один клик",
                  message: data.OneClickPurchaseCreate.message,
                });
              }
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.error = "Введите номер телефона";
        }
      }
    },
    showAlertModal(options) {
      this.$store.state._modals.push({
        component: AlertModal,
        options: options,
      });
    },
  },
  components: { IconComponent, InputComponent },
};
</script>

<style lang="stylus">
.click-buy-modal {
  width 100%
  max-width 382px

  & > &__body {
    gap: 15px
    padding-top 15px

    > .input {
      width 100%
    }
  }

  &__text {
    text-align center
  }

  &__footer .btn {
    width 100%
    justify-content center
  }
}
</style>
