<template>
  <div class="img" :class="{ 'img--loading': !loaded, 'img--contain': contain }">
    <img
      v-show="intersected || !lazy"
      :src="src || source"
      :alt="img ? img.alt : ''"
      loading="lazy"
      @load="loaded = true"
      @error="loaded = true"
    />
  </div>
</template>

<script>
export default {
  name: "ImgComponent",
  props: {
    src: String,
    img: Object,
    quality: {
      type: String,
      default: "90",
    },
    width: [Number, String],
    height: [Number, String],
    contain: Boolean,
    lazy: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      intersected: false, // находится ли фото в области видимости пользователя
      loaded: false, // фото загружено
      observer: null,
      observerOptions: {
        rootMargin: "200px",
      },
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.intersected = true;
        this.observer.disconnect();
      }
    }, this.observerOptions);
    this.observer.observe(this.$el);
  },
  destroyed() {
    this.observer.disconnect();
  },
  computed: {
    source() {
      if (this.img) {
        if (this.intersected || !this.lazy) {
          const urlParams = new URLSearchParams();
          if (this.width) urlParams.append("width", "" + this.width);
          if (this.height) urlParams.append("height", "" + this.height);
          if (this.quality) urlParams.append("quality", this.quality);
          return `${this.$store.state._env.MEDIA_ENDPOINT}/action/img/${this.img.id}?${urlParams.toString()}`;
        }
      }
      return "/static/images/no-image.png";
    },
  },
};
</script>

<style lang="stylus">
.img {
  position relative
  width 100%
  height 100%
  overflow hidden

  //&--loading {
  //  background-color: rgb(211,211,211);
  //  z-index: 4;
  //  overflow: hidden;
  //  border-radius: 5px;
  //
  //  &:after {
  //    content ""
  //    display block
  //    position: absolute;
  //    left: -45%;
  //    height: 100%;
  //    width: 45%;
  //    background-image: linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
  //    animation: loading 0.5s infinite;
  //    z-index: 5;
  //  }
  //}

  img {
    object-fit cover
    object-position center
    width 100%
    height 100%
  }

  &--contain img {
    object-fit contain
  }
}

//@keyframes loading {
//  0% {
//    left: -45%;
//  }
//  100% {
//    left: 100%;
//  }
//}
</style>
