<template>
  <router-link
    :to="{
      name: 'product',
      params: { id: data.parent_id || data.id, link: data.link },
      query: { set_id: data.parent_id ? data.id : undefined },
    }"
    class="product-card no-hover"
    v-if="data"
  >
    <div class="product-card__top">
      <div class="product-card__top-col">
        <span class="product-card__tag product-card__tag--new" v-if="isProductNew(data.created_at)">
          Новинка
        </span>
        <span class="product-card__tag product-card__tag--hit" v-if="data.is_popular">Популярное</span>
        <span
          class="product-card__tag product-card__tag--discount"
          v-if="data.join_share && data.join_share.share"
        >
          -{{ data.join_share.share.percent }}%
        </span>
        <span
          class="product-card__tag product-card__tag--sell"
          v-if="data.join_share && data.join_share.share"
        >
          Акция
        </span>
      </div>
      <div class="product-card__top-col product-card__top-col--right">
        <button
          :class="{ 'product-card__favorite--active': inFavorites }"
          type="button"
          class="product-card__favorite"
          :title="inFavorites ? 'Убрать из избранного' : 'В избранное'"
          @click.prevent="addToFavorites"
        >
          <IconComponent name="heart" />
        </button>
      </div>
    </div>
    <div class="product-card__image-container">
      <div class="ratio-container">
        <ImgComponent
          :img="data | item_img"
          width="800"
          height="800"
          quality="20"
          class="product-card__image"
        />
      </div>
    </div>
    <div class="product-card__container">
      <div class="product-card__content">
        <span class="product-card__category" v-if="data.category && data.category.title">
          {{ data.category.title }}
        </span>
        <span v-if="data.title" :title="data.title" class="product-card__title">
          {{ data.title }}
        </span>
      </div>
      <div class="product-card__bottom">
        <div class="product-card__prices">
          <!--          <span class="product-card__bonus">-->
          <!--            <span class="product-card__bonus-price"> 855 ₽ </span>-->
          <!--            <span class="product-card__bonus-percent"> -5% с бонусной картой </span>-->
          <!--          </span>-->
          <span class="product-card__price">
            <span v-if="!allSame">от</span>
            <span v-if="data.product_sets && data.product_sets.length">
              {{ data | product_price | formatPrice }}
            </span>
            <span v-else>{{ data.price | share_price(data) | formatPrice }}</span>
          </span>
          <span v-if="data.join_share && data.join_share.share" class="product-card__old-price">
            {{ data.price | formatPrice }}
          </span>
        </div>
        <div class="product-card__actions">
          <button
            @click.prevent="showClickBuyModal"
            type="button"
            class="btn btn--blue btn--sm"
            :class="{ active: inCart }"
          >
            Купить в 1 клик
          </button>
          <button
            @click.prevent="cartAdd"
            type="button"
            class="btn btn--red btn--sm"
            :class="{ 'btn--red': !inCart, 'btn--green': inCart }"
          >
            <IconComponent name="bag" />
            <span v-if="!inCart">В корзину</span>
            <span v-else>В корзине</span>
          </button>
          <button
            @click.prevent="cartAdd"
            type="button"
            class="btn btn--sm mobile"
            :class="{ 'btn--blue': !inCart, 'btn--green': inCart }"
          >
            <IconComponent name="bag" />
            <span v-if="!inCart">В корзину</span>
            <span v-else>В корзине</span>
          </button>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import LoginModal from "components/modals/components/LoginModal.vue";
import IconComponent from "components/IconComponent.vue";
import ClickBuyModal from "components/modals/components/ClickBuyModal.vue";
// import product from "../../mixins/product";

export default {
  name: "ProductCardComponent",
  props: {
    data: Object,
    small: Boolean,
  },
  // mixins: [product],
  data() {
    return {
      loading: false,
      count: 1,
    };
  },
  computed: {
    averageReviewsMark() {
      if (this.data && this.data.reviews && this.data.reviews.length) {
        let length = this.data.reviews.length;
        return Math.floor(this.data.reviews.map((r) => parseInt(r.mark)).reduce((a, b) => a + b, 0) / length);
      }
      return 0;
    },
    inFavorites() {
      return this.$store.state.auth.favorites_ids.includes(this.data.id);
    },
    isRegistered() {
      return !!this.$store.state.apollo_token || this.$store.state.auth.user;
    },
    inCart() {
      return this.data && !!this.$store.state.auth.cart.find((c) => c.item?.id === this.data?.id);
    },
    needVariationSelect() {
      if (this.data.product_sets && this.data.product_sets.length) {
        return !this.data.product_sets.find((item) => item.id === this.data.id);
      }
      return false;
    },
    allSame() {
      let sets = this.data.product_sets || [];
      return sets.every((i) => i.price === sets[0].price);
    },
  },
  methods: {
    buyProduct() {
      if (this.data.product_sets && this.data.product_sets.length) {
        this.$router.push({ name: "product", params: { id: this.data.id } });
      } else {
        this.cartAdd();
      }
    },
    cartAdd() {
      // если вариации есть, но ни одна не выбрана
      if (this.needVariationSelect) {
        this.$router.push({ name: "product", params: { id: this.data.id } });
      } else {
        // если такого товара нет в корзине, то добавляем
        if (!this.inCart) {
          this.$store.state.auth.cart.unshift({
            count: this.count,
            item: this.data,
          });
        } else {
          this.$router.push({ name: "cart" });
        }
        if (this.isRegistered && !this.loading) {
          this.$store
            .dispatch("CART_ADD_ITEMS", {
              apollo: this.$apollo,
              variables: {
                items: [
                  {
                    id: this.data.id,
                    count: this.count,
                  },
                ],
              },
            })
            .then(() => {
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              console.error(error);
            });
        }
      }
    },
    async addToFavorites() {
      if (this.isRegistered) {
        let index = this.$store.state.auth.favorites_ids.indexOf(this.data.id);
        if (index !== -1) {
          this.$store.state.auth.favorites_ids.splice(index, 1);
        } else {
          this.$store.state.auth.favorites_ids.push(this.data.id);
        }
        await this.$store.dispatch("FAVORITE_CREATE_OR_DELETE", {
          apollo: this.$apollo,
          variables: {
            item_id: this.data.id,
          },
        });
      } else {
        this.showLoginModal();
      }
    },
    isProductNew(date) {
      let oneDay = 1000 * 60 * 60 * 24;
      return date ? Math.floor((Date.now() - new Date(date).getTime()) / oneDay) <= 14 : false;
    },
    showLoginModal() {
      this.$store.state._modals.push({
        component: LoginModal,
        options: {},
      });
    },
    showClickBuyModal() {
      this.$store.state._modals.push({
        component: ClickBuyModal,
        options: {
          // если у товара есть вариации, то надо показывать модалку выбора вариаций,
          // потом уже модалку офрмления заказа "в один клик"
          product: this.data.id,
        },
      });
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/maxlines.styl"
@import "~@/styles/mixins/ratio.styl"
.product-card {
  background: var(--white);
  border-radius: var(--main-radius);
  display inline-flex
  flex-direction column
  position relative
  border: 1px solid var(--gray);
  color: var(--dark)
  width 100%
  max-width 386px
  height: 100%
  cursor: pointer;
  overflow hidden
  transition var(--transition)

  &:hover {
    +above(1025px) {
      filter brightness(0.9)
      //transform scale(1.03)
    }
  }

  &__favorite {
    border-radius: var(--small-radius);
    border none
    background var(--white)
    cursor pointer
    padding 9px
    z-index 1
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition var(--transition)

    &:hover {
      background var(--gray)
    }

    &--active& {
      .icon svg path {
        fill var(--main)
      }
    }

    .icon {
      width 18px
      height 18px

      svg path {
        fill var(--dark-light)
        transition var(--transition)
      }
    }
  }

  &__top {
    absolute left top
    width 100%
    display flex
    justify-content space-between
    align-items flex-start
    z-index 2
    padding 10px
  }

  &__top-col--right {
    align-items flex-end
  }

  &__top-col {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    gap 8px
    +below(420px) {
      gap: 4px
    }
  }

  &__tag {
    font-size: 10px;
    line-height: 120%
    padding 5px
    border-radius: var(--small-radius)
    color var(--white)
    +below(420px) {
      padding 4px
    }

    &--new {
      background var(--green)
      color var(--dark)
    }

    &--discount {
      background var(--red)
    }

    &--hit {
      background var(--yellow)
    }

    &--sell {
      background var(--red)
    }
  }

  &__image-container {
    position relative
    background: none;
    border: none;
    border-bottom 1px solid var(--gray)
    overflow hidden
    ratio(1, 1, contain)
  }

  &__image {
    height 100vh
    max-height 320px
    background-color var(--white)
  }

  &__container {
    display flex
    flex-direction column
    gap 10px
    flex-grow 1
    padding 10px
    +below(400px) {
      padding 6px
    }
  }

  &__content {
    display flex
    flex-direction column
    gap 5px
  }

  &__category {
    maxlines(2)
    font-size: 0.75em
    line-height: 14px;
    color: var(--dark-light);
  }

  &__title {
    font-weight 700
    height 78px
    maxlines(3)
    +below(540px) {
      maxlines(4)
    }
    +below(540px) {
      font-size: 14px;
      line-height: 18px;
      height 72px
    }
  }

  &__bottom {
    display flex
    flex-direction column
    gap 10px
    margin-top auto
  }

  &__prices {
    display flex
    flex-flow row wrap
    align-items center
    gap: 5px
  }

  &__bonus {
    width 100%
    display flex
    align-items center
    gap: 5px

    &-price {
      flex-shrink 0
      font-weight: 700;
      font-size: 1.125em;
      line-height: 1.55
      color: var(--white)
      background: var(--red)
      border-radius: var(--small-radius)
      padding 0 5px
    }

    &-percent {
      font-size: 0.75em
      line-height: 14px;
      color: var(--red);
    }
  }

  &__price {
    font-weight: 700;
    white-space nowrap
    color var(--dark-light)
    padding 0 5px
  }

  //&__cart-add {
  //  &.active {
  //    background var(--brown-light)
  //  }
  //}

  &__old-price {
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-decoration-line: line-through;
    text-decoration-color var(--red)
    color var(--dark-light)
    padding 0 7px
  }

  &__actions {
    display flex
    gap: 10px

    .btn {
      flex 1
      font-size 1em
      +below(768px) {
        font-size 0.875em
      }

      .icon {
        margin-top -4px
      }


      &:not(.mobile) {
        +below(560px) {
          display none
        }
      }

      &.mobile {
        +above(561px) {
          display none
        }
      }
    }
  }
}
</style>
