import Vue from "vue";
import moment from "moment";
import { VueMaskFilter } from "v-mask";
import plural from "plural-ru";
import { locale } from "moment/moment";

Vue.filter("formatDate", (date) => {
  return moment(date).format("DD.MM.YYYY");
});

Vue.filter("formatDateTime", (date) => {
  return moment(date).format("DD.MM.YYYY HH:mm");
});

Vue.filter("humanDate", (date) => {
  moment.locale("ru");
  return moment(date).format("DD MMMM YYYY");
});

Vue.filter("humanDateTime", (date) => {
  moment.locale("ru");
  return moment(date).format("DD MMMM YYYY\u002C HH:mm");
});

Vue.filter("humanDateMonth", (date) => {
  moment.locale("ru");
  return moment(date).format("DD MMMM");
});

Vue.filter("robotDate", (date) => {
  return moment(date);
});

Vue.filter("plural", (count, ...words) => {
  return plural(count, ...words.map((w) => "%d " + w));
});

Vue.filter("plural_word", (count, ...words) => {
  return plural(count, ...words.map((w) => w));
});

Vue.filter("vMask", VueMaskFilter);

Vue.filter("image", (entity, api) => {
  if (entity && entity.path) {
    if (entity.path.search(/(http:|https:)/) !== -1) {
      return entity.path;
    } else {
      return api + entity.path;
    }
  }
  return "/static/images/no-image.png";
});

Vue.filter("image_by_id", (img, state, { width, height, quality = 99 } = {}) => {
  if (img) {
    const urlParams = new URLSearchParams();
    if (width) urlParams.append("width", "" + width);
    if (height) urlParams.append("height", "" + height);
    if (quality) urlParams.append("quality", quality);
    return `${state._env.MEDIA_ENDPOINT}/action/img/${img.id}?${urlParams.toString()}`;
  }
  return "/static/images/no-image.png";
});

Vue.filter("image_alt", (entity) => {
  if (entity && entity.alt) {
    return entity.alt;
  }
  return "";
});

Vue.filter("formatPrice", (price) => {
  if (price) {
    return (
      parseFloat(price).toLocaleString("ru-RU", {
        maximumFractionDigits: 0,
      }) + " ₽"
    );
  }
  return 0 + " ₽";
});

/**
 * Получение цены после скидки, если она есть
 */
Vue.filter("share_price", (price, entity) => {
  if (entity?.promo_share?.share) {
    return (parseFloat(price) * (100 - parseFloat(entity.promo_share.share.percent))) / 100;
  }
  if (entity?.join_share?.share) {
    return (parseFloat(price) * (100 - parseFloat(entity.join_share.share.percent))) / 100;
  }
  if (entity?.join_share_set?.share) {
    return (parseFloat(price) * (100 - parseFloat(entity.join_share_set.share.percent))) / 100;
  }
  return price;
});

/**
 * Вернуть только числа из строки
 */
Vue.filter("phone", (str) => {
  return str ? "tel:" + str.replace(/\D+/g, "") : undefined;
});

/**
 * Получить фото товара
 */
Vue.filter("item_img", (item) => {
  if (item.head_img) {
    return item.head_img;
  }
  if (item.images && item.images.length) {
    return item.images[0];
  }
  if (item.parent && item.parent.head_img) {
    return item.parent.head_img;
  }
  return null;
});

/**
 * Проверка на наличие текста в EditorJs
 * если это текст от EditorJs и там пусто - false
 * undefined/null/'null' - false
 * любой другой текст - true
 */
Vue.filter("editor_has_text", (text) => {
  try {
    let parsedText = JSON.parse(text);
    if (typeof parsedText === "object") {
      return !!(parsedText && parsedText.blocks && parsedText.blocks.length);
    }
    return !!text;
  } catch (e) {
    if (typeof text === "string") {
      return !!text;
    }
    return false;
  }
});

/**
 * Ссылка для iframe
 */
Vue.filter("iframe_link", (link) => {
  if (link) {
    if (link.includes("<iframe")) {
      return link;
    }

    let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    let match = link.match(regExp);
    if (match && match[2].length == 11) {
      return "https://www.youtube.com/embed/" + match[2];
    }
    return undefined;
  }
  return undefined;
});

/**
 * Не пустой объект
 */
Vue.filter("not_empty", (object) => {
  return object && Object.keys(object).length;
});

/**
 * Цена родительского товара
 * Если у всех вариаций одинаковая цена, то это цена родителя
 * Если разная - то от мин. цены
 */
Vue.filter("product_price", (product = {}) => {
  let sets = product.product_sets;
  if (sets && sets.length) {
    const allSame = sets.every((i) => i.price === sets[0].price);
    if (allSame) {
      return sets[0].price;
    }
    return Math.min(...sets.map((i) => i.price));
  }
  return product.price;
});
