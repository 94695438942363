<template>
  <div class="socials">
    <a v-for="(item, i) in socials" :key="i" :href="item.link" target="_blank">
      <figure class="icon" v-html="item.value"></figure>
    </a>
  </div>
</template>

<script>
export default {
  name: "SocialsComponent",
  computed: {
    socials() {
      if (
        this.$store.state.global.info &&
        this.$store.state.global.info.communications &&
        this.$store.state.global.info.communications.length
      ) {
        return this.$store.state.global.info.communications.filter(
          (item) => this.$store.state._types.SOCIAL === item.type.code
        );
      }
      return [];
    },
  },
};
</script>

<style lang="stylus">
.socials {
  display flex
  align-items center
  gap 7px
  justify-content flex-start

  a {
    display flex
    align-items center
    justify-content center
    width 40px
    height 40px
    border-radius var(--main-radius)
    border 1px solid var(--border-color)

    &:hover {
      border-color var(--main)
    }

    .icon {
      width 22px
      height 22px

      svg path {
        fill var(--dark)
      }
    }
  }
}
</style>
