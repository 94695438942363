<template>
  <div class="default-menu catalog-menu">
    <div class="default-menu__header">
      <div class="default-menu__header-top">
        <span class="default-menu__title">Каталог</span>
        <button type="button" class="default-menu__close" @click="$emit('close')">
          <IconComponent name="close-square" />
        </button>
      </div>
    </div>
    <div class="default-menu__body catalog-menu__body">
      <ul class="catalog-menu__links">
        <li v-if="active" class="catalog-menu__item catalog-menu__item--active">
          <a @click.prevent="back" href="#" class="catalog-menu__link">
            <IconComponent name="arrow-left-2" />
            <span>{{ active.title }}</span>
          </a>
        </li>
        <li v-for="(item, i) in categories" :key="i" class="catalog-menu__item">
          <a @click.prevent="click(item)" href="#" class="catalog-menu__link">
            <ImgComponent class="catalog-menu__link-img" :img="item.head_img" />
            <span class="catalog-menu__link-title">{{ item.title }}</span>
            <IconComponent v-if="item.childs && item.childs.length" name="arrow-right-3" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "CatalogMenu",
  data() {
    return {
      actives: [],
    };
  },
  computed: {
    categories() {
      return this.active?.childs || this.$store.state.global.categories;
    },
    active() {
      if (this.actives && this.actives.length) {
        return this.actives[this.actives.length - 1];
      }
      return undefined;
    },
  },
  methods: {
    click(item) {
      if (item.childs && item.childs.length) {
        this.actives.push(item);
      } else {
        this.$router.push({ name: "catalog", params: { id: item.id } });
      }
    },
    back() {
      this.actives.pop();
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.catalog-menu {
  background var(--gray) !important

  &__links {
    display flex
    flex-direction column
    gap: 5px
  }

  &__item {
    flex-shrink 0

    &--active {
      .icon {
        width: 20px
        height: 20px
      }
    }
  }

  &__link {
    display flex
    justify-content flex-start
    align-items center
    gap: 10px
    padding 5px
    min-height 50px
    border-radius var(--main-radius)
    background var(--white)

    &:hover {
      color var(--main)
    }

    .icon {
      width 20px
      height: 20px
    }

    &-img {
      flex-shrink 0
      width 40px
      height 40px
      border: 2px solid var(--white)
      border-radius: var(--small-radius)
    }

    &-title {
      flex-grow 1
    }
  }
}
</style>
