<template>
  <div class="default-menu sidenav-menu">
    <div class="default-menu__header">
      <div class="default-menu__header-top">
        <button type="button" class="default-menu__close" @click="$emit('close')">
          <IconComponent name="close-square" />
        </button>
      </div>
    </div>
    <div class="default-menu__body sidenav-menu__body">
      <ul class="default-menu__links">
        <li v-for="(item, i) in list" :key="i + 'list'">
          <router-link :to="item.route" class="default-menu__link">
            <span>{{ item.title }}</span>
          </router-link>
        </li>
        <li v-for="(page, i) in pages" :key="i">
          <router-link
            class="default-menu__link"
            :to="{ name: 'static', params: { id: page.id, link: page.link } }"
          >
            <span> {{ page.title }} </span>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="default-menu__footer sidenav-menu__footer">
      <ContactComponent class="sidenav-menu__contact" :data="email" />
      <ContactComponent class="sidenav-menu__contact" :data="phone" />
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import ContactComponent from "components/ContactComponent.vue";

export default {
  name: "SidenavMenu",
  data() {
    return {
      list: [
        {
          title: "Каталог",
          route: { name: "catalog" },
        },
        {
          title: "Акции",
          route: { name: "shares" },
        },
        {
          title: "Избранное",
          route: { name: "user", params: { link: "wishlist" } },
        },
        {
          title: "Корзина",
          route: { name: "cart" },
        },
        {
          title: "Личный кабинет",
          route: { name: "user" },
        },
      ],
    };
  },
  computed: {
    communications() {
      return this.$store.state.global.info?.communications || [];
    },
    phone() {
      return this.communications.find((item) => item.type.code === this.$store.state._types.PHONE);
    },
    email() {
      return this.communications.find((item) => item.type.code === this.$store.state._types.EMAIL);
    },
    pages() {
      return this.$store.state.global.pages || [];
    },
  },
  components: { ContactComponent, IconComponent },
};
</script>
