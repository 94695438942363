import cart_page from "@/graphql/pages/CartPage.graphql";
import cart_page_no_auth from "@/graphql/pages/CartPageNoAuth.graphql";
import CartAddItems from "@/graphql/mutations/CartAddItems.graphql";
import CartDeleteItem from "@/graphql/mutations/CartDeleteItem.graphql";
import FavoriteCreateOrDelete from "@/graphql/mutations/FavoriteCreateOrDelete.graphql";
import FavoriteDeleteAll from "@/graphql/mutations/FavoriteDeleteAll.graphql";
import favorites from "@/graphql/queries/favorites.graphql";
import addresses from "@/graphql/queries/addresses.graphql";
import user from "@/graphql/auth/me.graphql";

async function ME({ state }, { apollo }) {
  let token = state.apollo_token;
  await apollo
    .query({
      query: user,
      context: {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    })
    .then(({ data }) => {
      state.auth.user = data.me;
      if (data.carts) state.auth.cart = data.carts;
      if (data.favorites) state.auth.favorites_ids = data.favorites.map((item) => item.item_id);
      if (data.favorites) state.auth.favorites = data.favorites;
    });
}

async function GET_CART_ITEMS({ state }, { apollo }) {
  let token = state.apollo_token;
  await apollo
    .query({
      query: token ? cart_page : cart_page_no_auth,
      fetchPolicy: "network-only",
      context: {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    })
    .then(({ data }) => {
      if (data.carts) state.auth.cart = data.carts;
      state.auth.deliveries = data.deliveries;
      // дефолтные данные
      state.global.header_categories = data.header_categories;
      state.global.footer_categories = data.footer_categories;
      state.global.categories = data.categories;
      state.global.brands = data.brands;
      state.global.info = data.info || {};
      state.global.pages = data.pages;
      state.global.branches = data.filials;
    });
}

async function CART_ADD_ITEMS({ state }, { apollo, variables }) {
  return await apollo.mutate({
    mutation: CartAddItems,
    variables: variables,
    context: {
      headers: {
        Authorization: "Bearer " + state.apollo_token,
      },
    },
  });
}

async function CART_DELETE_ITEMS({ state }, { apollo, variables }) {
  return await apollo.mutate({
    mutation: CartDeleteItem,
    variables: variables,
    context: {
      headers: {
        Authorization: "Bearer " + state.apollo_token,
      },
    },
  });
}

async function FAVORITE_CREATE_OR_DELETE({ state }, { apollo, variables }) {
  return await apollo.mutate({
    mutation: FavoriteCreateOrDelete,
    variables: variables,
    context: {
      headers: {
        Authorization: "Bearer " + state.apollo_token,
      },
    },
  });
}

async function FAVORITE_DELETE_ALL({ state }, { apollo }) {
  return await apollo.mutate({
    mutation: FavoriteDeleteAll,
    context: {
      headers: {
        Authorization: "Bearer " + state.apollo_token,
      },
    },
  });
}

async function GET_FAVORITES({ state }, { apollo }) {
  await apollo
    .query({
      query: favorites,
      fetchPolicy: "network-only",
      context: {
        headers: {
          Authorization: "Bearer " + state.apollo_token,
        },
      },
    })
    .then(({ data }) => {
      if (data.favorites) state.auth.favorites_ids = data.favorites.map((item) => item.item_id);
      if (data.favorites) state.auth.favorites = data.favorites;
    });
}

async function GET_ADDRESSES({ state }, { apollo }) {
  await apollo
    .query({
      query: addresses,
      fetchPolicy: "network-only",
      context: {
        headers: {
          Authorization: "Bearer " + state.apollo_token,
        },
      },
    })
    .then(({ data }) => {
      state.auth.addresses = data.addresses;
    });
}

export default Object.assign({
  ME,
  GET_CART_ITEMS,
  CART_ADD_ITEMS,
  CART_DELETE_ITEMS,
  FAVORITE_CREATE_OR_DELETE,
  FAVORITE_DELETE_ALL,
  GET_FAVORITES,
  GET_ADDRESSES,
});
