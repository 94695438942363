<template>
  <div class="slider-arrows">
    <button
      type="button"
      title="Влево"
      class="slider-arrows__item"
      :disabled="swiper && !options.loop && swiper.isBeginning"
      @click="$emit('left')"
    >
      <IconComponent name="arrow-left" />
    </button>
    <button
      type="button"
      title="Вправо"
      class="slider-arrows__item"
      :disabled="swiper && !options.loop && swiper.isEnd"
      @click="$emit('right')"
    >
      <IconComponent name="arrow-right" />
    </button>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "SliderArrowsComponent",
  props: {
    swiper: Object,
    options: Object,
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.slider-arrows {
  z-index: 1;
  display flex
  justify-content: center
  gap 15px
  margin-top: 30px

  &__item {
    position relative
    z-index: 1;
    background: transparent
    box-sizing: border-box;
    border-radius: 50%
    width 44px
    height 44px
    display flex
    align-items center
    justify-content center
    cursor pointer
    margin auto 0
    border: 2px solid transparent;
    transition var(--transition)

    .icon {
      width: 36px
      height: 36px

      svg path {
        fill var(--border-color)
      }
    }

    &:not([disabled]) {
      &:hover {
        border-color var(--blue)
      }
    }

    &[disabled] {
      cursor: default
      border: 1px solid var(--gray);
      background transparent

      //.icon svg path {
      //  stroke var(--brown)
      //}
    }
  }
}
</style>
