<template>
  <div class="subscribe">
    <div class="container-padding">
      <div class="subscribe__inner">
        <h2 class="subscribe__title">Узнайте в числе первых о скидках, распродажах и новинках</h2>
        <div class="subscribe__form-container">
          <form @submit.prevent="submit" class="subscribe__form">
            <InputComponent
              v-model="form.email.value"
              :error="form.email.error"
              placeholder="Ваш электронный ящик"
              title="Email"
              type="email"
              modified
              required
            />
            <button type="submit" class="btn btn--lg btn--red">
              <IconComponent v-if="loading" name="loading" />
              <template v-else>Подписаться</template>
            </button>
          </form>
          <span class="subscribe__terms">Нажимая на кнопку, вы соглашаетесь с политикой приватности</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import IconComponent from "components/IconComponent.vue";
import EMAIL_SUBSCRIPTION_CREATE from "@/graphql/mutations/EmailSubscriptionCreate.graphql";
import AlertModal from "components/modals/components/AlertModal.vue";

export default {
  name: "SubscribeComponent",
  data() {
    return {
      loading: false,
      form: {
        email: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
      },
    };
  },
  methods: {
    submit() {
      if (!this.loading) {
        this.loading = true;
        this.resetErrors("form");
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: EMAIL_SUBSCRIPTION_CREATE,
            variables: {
              email: this.form.email.value,
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.apollo_token,
              },
            },
          })
          .then(() => {
            this.loading = false;
            this.resetForm("form");
            this.$store.state._modals.push({
              component: AlertModal,
              options: {
                title: "Успешно",
                message: "Спасибо, мы будем оповещать о всех важных новостях",
              },
            });
          })
          .catch(({ graphQLErrors }) => {
            this.loading = false;
            this.parseGqlErrors(graphQLErrors);
          });
      }
    },
    resetForm(form) {
      Object.keys(this[form]).forEach((key) => {
        this[form][key].value = undefined;
      });
    },
    resetErrors(form) {
      Object.keys(this[form]).forEach((key) => {
        this[form][key].error = undefined;
      });
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].error = err.extensions.validation[key][0];
            }
          });
        }
      });
    },
  },
  components: { IconComponent, InputComponent },
};
</script>

<style lang="stylus">
.subscribe {
  position relative
  background var(--dark)
  overflow hidden
  padding 40px 0 32px
  display flex
  align-items center
  +below(440px) {
    padding 30px 0
  }

  &__inner {
    position relative
    z-index 1
    display grid
    grid-template-columns 1fr 1fr
    grid-gap var(--gap)
    align-items center
    +below(860px) {
      grid-template-columns 1fr
      grid-gap 20px
    }
  }

  &__title {
    line-height 1.35
    text-transform: uppercase;
    color: var(--white);
    margin 0
  }

  &__form {
    display flex
    gap: 20px
    align-items flex-start
    +below(520px) {
      flex-direction column
      align-items stretch
      gap: 15px
    }

    .input {
      flex-grow 1

      &__container {
        border none
      }
    }

    .btn {
      height 60px
      width 100%
      max-width 130px
      +below(520px) {
        max-width 100%
      }
    }

    &-container {
      display flex
      flex-direction column
      gap: 10px
    }
  }

  &__terms {
    font-size: 0.875em
    line-height: 20px;
    color: var(--border-color)
  }
}
</style>
