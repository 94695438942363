<template>
  <div class="header-main">
    <div class="container-padding">
      <div class="header-main__container">
        <div class="header-main__mobile-top">
          <router-link :to="{ name: 'home' }" class="header-main__logo no-hover" title="На главную">
            <IconComponent name="logo" />
          </router-link>
          <button @click="showMenu" class="header-main__menu btn" type="button">
            <IconComponent name="menu" />
          </button>
        </div>
        <button @click="showCatalog" class="header-main__catalog btn btn--lg btn--blue" type="button">
          <IconComponent :name="$store.state._overlays.categoriesOverlay ? 'close' : 'element'" />
          <span>Каталог</span>
        </button>
        <div
          class="header-main__search"
          :class="{ 'header-main__search--focus': $store.state._overlays.searchOverlay }"
        >
          <InputComponent
            ref="search"
            @input="$emit('search', search)"
            @enter="searchPage"
            @action="searchPage"
            @focus="$store.state._overlays.searchOverlay = true"
            v-model="search"
            icon-position="left"
            type="search"
            placeholder="Я ищу..."
            class="header-main__search-input"
          />
          <button
            v-if="$store.state._overlays.searchOverlay"
            @click="$store.state._overlays.searchOverlay = false"
            class="header-main__action header-main__search-close btn"
          >
            <IconComponent name="close-square" />
          </button>
        </div>
        <div class="header-main__actions">
          <button
            type="button"
            class="header-main__action header-main__action-mobile header-main__action-search"
            title="Поиск по сайту"
            @click="$store.state._overlays.searchOverlay = true"
          >
            <IconComponent name="search" />
            <span class="header-main__action-title">Поиск</span>
          </button>
          <router-link :to="{ name: 'user' }" class="header-main__action">
            <IconComponent name="user" />
            <span v-if="isRegistered" class="header-main__action-title">Кабинет</span>
            <span v-else class="header-main__action-title">Войти</span>
          </router-link>
          <router-link
            :to="{ name: 'user', params: { link: 'wishlist' } }"
            class="header-main__action header-main__action-favorites"
          >
            <IconComponent name="heart" />
            <span class="header-main__action-title">Избранное</span>
            <span v-show="favoritesLength" class="header-main__action-count">{{ favoritesLength }}</span>
          </router-link>
          <router-link :to="{ name: 'cart' }" class="header-main__action">
            <IconComponent name="bag" />
            <span class="header-main__action-title">Корзина</span>
            <span v-show="cartItemsLength" class="header-main__action-count">{{ cartItemsLength }}</span>
          </router-link>
          <button
            @click="showMenu"
            type="button"
            class="header-main__action header-main__action-mobile header-main__action-menu"
          >
            <IconComponent name="menu" />
          </button>
        </div>
      </div>
    </div>
    <HeaderDropdownComponent />
    <SearchResultsDropdownComponent
      v-show="search"
      :items="products"
      :total="total"
      :search="search"
      buttonTitle="Показать все"
      :route="{ name: 'search', query: { title: search } }"
      :loading="loading"
    />
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import SidenavMenu from "components/menus/components/SidenavMenu.vue";
import CatalogMenu from "components/menus/components/CatalogMenu.vue";
import SearchResultsDropdownComponent from "components/header/components/SearchResultsDropdownComponent.vue";
import IconComponent from "components/IconComponent.vue";
import HeaderDropdownComponent from "components/header/components/HeaderDropdownComponent.vue";

export default {
  name: "HeaderMainComponent",
  props: {
    cartItemsLength: Number,
    products: Array,
    total: Number,
    loading: Boolean,
  },
  data() {
    return {
      search: null,
    };
  },
  mounted() {
    if (this.$route.name === "search" && this.$route.query.title) {
      this.search = this.$route.query.title;
      this.$emit("search", this.search);
    }
  },
  watch: {
    "$route.name"() {
      this.$refs.search.callBlur();
      if (this.$route.name !== "search") {
        this.search = null;
      }
    },
    "$route.params"() {
      this.$refs.search.callBlur();
      if (this.$route.name !== "search") {
        this.search = null;
      }
    },
  },
  computed: {
    isRegistered() {
      return !!this.$store.state.apollo_token || this.$store.state.auth.user;
    },
    favoritesLength() {
      return this.$store.state.auth.favorites_ids?.length;
    },
    phone() {
      if (
        this.$store.state.marketplace?.communications &&
        this.$store.state.marketplace.communications.length
      ) {
        return this.$store.state.marketplace.communications.find(
          (item) => item.type.code === this.$store.state._types.PHONE
        )?.value;
      }
      return undefined;
    },
    schedule() {
      return this.$store.state.marketplace?.schedule || undefined;
    },
  },
  methods: {
    searchPage() {
      this.$router.push({ name: "search", query: { title: this.search } });
    },
    showMenu() {
      this.$store.state._menus.push({
        component: SidenavMenu,
        options: {},
        alignRight: true,
      });
    },
    showCatalog() {
      if (window.innerWidth <= 1025) {
        this.$store.state._menus.push({
          component: CatalogMenu,
          options: {},
        });
      } else {
        this.$store.state._overlays.categoriesOverlay = !this.$store.state._overlays.categoriesOverlay;
      }
    },
  },
  components: {
    IconComponent,
    SearchResultsDropdownComponent,
    InputComponent,
    HeaderDropdownComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
.header-main {
  display flex
  align-items center
  justify-content center
  position relative
  min-height var(--header-main-height)
  background var(--bg-color)
  transition box-shadow var(--transition)
  padding 20px 0
  +below(860px) {
    padding-top 15px
  }

  &__container {
    width 100%
    position relative
    display inline-flex
    align-items center
    justify-content space-between
    gap 30px
    +below(860px) {
      flex-wrap wrap
      gap 15px
    }

    .btn {
      flex-shrink 0
    }
  }

  &__mobile-top {
    +below(860px) {
      width 100%
      display flex
      gap: 20px
      justify-content space-between
    }
  }

  &__logo {
    flex-shrink 0
    display flex
    padding 10px 0
    +below(540px) {
      padding 0
    }

    .icon {
      width 200px
      height auto
      +below(540px) {
        width 150px
      }
    }
  }

  &__menu.btn {
    +above(861px) {
      display none
    }

    .icon {
      width 35px
      height: 35px
    }
  }

  &__catalog {
    > span {
      +below(410px) {
        display none
      }
    }
  }

  &__search {
    display flex
    align-items center
    gap: 15px
    flex-grow 1
    background var(--bg-color)
    +below(1100px) {
      display none
    }

    &-input {
      width 100%
      +below(540px) {
        height 40px
      }

      .icon {
        margin-top -4px
      }

      input::placeholder {
        font-weight: normal;
        color var(--dark-light)
      }

    }

    &--focus {
      //display inline-flex
      //absolute left 0 right 0
      //z-index 1
      +below(1100px) {
        display inline-flex
        absolute left 0 right 0
        z-index 1
      }
      +below(860px) {
        bottom 0
      }
    }

    &-close.btn {
      background var(--bg-color)
      margin-right -18px
      +above(1101px) {
        display none
      }
      +below(860px) {
        margin-right -16px
      }
      +below(540px) {
        margin-right -5px
      }

      .icon {
        width 24px
        height 24px
      }

      &:hover {
        .icon svg {
          svg(var(--red))
        }
      }
    }
  }

  &__actions {
    display flex
    align-items center
    gap 15px
    flex-shrink 0
    margin-left auto
    +below(540px) {
      gap: 5px
    }
  }

  &__action {
    display flex
    flex-direction column
    align-items center
    justify-content center
    gap: 5px
    width 60px
    height 60px
    border-radius var(--main-radius)
    position relative
    transition var(--transition)
    cursor pointer
    flex-shrink 0
    color var(--dark-light)
    font-size: 0.625em
    line-height: 1.2
    background none
    border none
    +below(540px) {
      width 40px
      height 40px
      padding 10px
      border: 1px solid var(--border-color);
      border-radius var(--big-radius)
    }

    &:hover {
      color var(--blue)
      border-color var(--blue)

      .icon svg {
        svg(var(--blue))
      }
    }

    &-title{
      +below(540px) {
        display none
      }
    }

    &-mobile {
      +above(1100px) {
        display none
      }
    }

    &-menu {
      +below(860px) {
        display none
      }
    }

    &-favorites {
      +below(350px) {
        display none
      }
    }

    .icon {
      width 25px
      height 25px
      +below(540px) {
        width 18px
        height 18px
      }

      svg {
        svg(var(--dark))
      }
    }
  }

  &__action-count {
    display inline-flex
    align-items center
    justify-content center
    text-align center
    absolute right 13px top 10px
    height 14px
    min-width 14px
    background: var(--red);
    border: 2px solid var(--bg-color);
    box-sizing: border-box;
    border-radius: 100px
    font-size: 1em
    line-height 10px
    color: var(--white);
    padding 1px 3px
    +below(540px) {
      top: 2px;
      right: 2px
    }
  }
}
</style>
