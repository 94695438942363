<template>
  <div class="slider" :class="{ 'slider--side-arrows': sideArrows }" v-if="items && items.length">
    <div class="slider__container">
      <div class="slider__header" :class="{ 'slider__header--center': centerTitle }" v-if="title">
        <h3 class="slider__title">{{ title }}</h3>
        <router-link :to="route" class="btn btn--hollow-dark btn--sm" v-if="buttonTitle">
          <span>{{ buttonTitle }}</span>
          <RemixIconComponent category="System" name="arrow-right-line" />
        </router-link>
      </div>
      <div :class="{ 'slider__items--visible': ready }" class="slider__items">
        <div class="swiper" ref="items">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, i) in items" :key="i">
              <slot :item="item"></slot>
            </div>
          </div>
        </div>
        <SliderArrowsComponent
          v-if="swiper && !hideArrows && canShowArrows"
          @left="swiper.slidePrev()"
          @right="swiper.slideNext()"
          :swiper="swiper"
          :options="sliderOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import SliderArrowsComponent from "components/SliderArrowsComponent.vue";

export default {
  name: "SliderComponent",
  props: {
    title: String,
    buttonTitle: String,
    items: Array,
    hideArrows: Boolean,
    sideArrows: Boolean,
    centerTitle: Boolean,
    route: {
      type: Object,
      default() {
        return {
          name: "",
        };
      },
    },
    sliderOptions: {
      type: Object,
      default() {
        return {
          slidesPerView: "auto",
          spaceBetween: 24,
          pagination: false,
          clickable: false,
        };
      },
    },
  },
  data() {
    return {
      swiper: null,
      ready: false,
    };
  },
  computed: {
    canShowArrows() {
      if (this.items.length <= 1) {
        return false;
      }
      if (this.sliderOptions.loop) {
        return true;
      }
      let slidesPerView = this.getCurrentBreakpoint().slidesPerView;
      if (typeof slidesPerView === "string") {
        return slidesPerView === "auto";
      }
      if (typeof slidesPerView === "number") {
        return this.items.length > slidesPerView;
      }
      return false;
    },
  },
  mounted() {
    require(["swiper/swiper-bundle.esm.browser.js"], ({ Swiper }) => {
      this.swiper = new Swiper(this.$refs.items, this.sliderOptions);
      this.ready = true;
    });
  },
  methods: {
    getCurrentBreakpoint() {
      if (this.sliderOptions.breakpoints && typeof window !== "undefined") {
        let returnCandidate = this.sliderOptions;
        Object.keys(this.sliderOptions.breakpoints).forEach((key) => {
          if (window.innerWidth > parseInt(key)) {
            returnCandidate = this.sliderOptions.breakpoints[key];
          }
        });
        return returnCandidate;
      }
      return this.sliderOptions;
    },
  },
  components: { SliderArrowsComponent, RemixIconComponent },
};
</script>

<style lang="stylus">
@import "~swiper/swiper-bundle.min.css"
.swiper-slide {
  width auto
  max-width 100%
  display inline-flex
}

.slider {
  display flex
  justify-content center
  align-items center

  &--side-arrows {
    .slider__items {
      padding 0 90px
      +below(840px) {
        padding: 0 50px
      }
      +below(580px) {
        padding: 0
      }
    }

    .slider-arrows {
      margin: 0
      +below(540px) {
        display none
      }

      &__item {
        svg path {
          fill var(--border-color)
        }

        &:hover {
          border-color var(--blue)
        }
      }
    }
  }

  &__title {
    font-weight: normal;
    font-size: 1.5em;
    line-height: 32px;
    color: var(--body-color);
  }

  &__items {
    position relative
    width 100%
    height 100%
    opacity 0
    transition var(--transition)

    &--visible {
      opacity 1
    }

    .swiper {
      height 100%
    }
  }

  &__container {
    width 100%
    height: 100%
  }

  &__header {
    display flex
    align-items center
    width 100%
    margin-bottom 30px
    justify-content space-between
    gap: 24px
    +below(860px) {
      display grid
      grid-gap 16px
      justify-content stretch

      .btn {
        width 100%
        justify-content center
      }
    }

    &--center {
      justify-content: center
    }
  }

  &__title {
    font-weight: 500;
    font-size: 1.5em;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: var(--body-color);
    margin 0
  }
}
</style>
