<template>
  <div class="map-container">
    <div class="map-info">
      <h2 class="map-title">
        {{ addresses.length | plural("филиал", "филиала", "филиалов") }} по Северному Кавказу в шаге от вас
      </h2>
      <a
        href="https://yandex.ru/maps/28/makhachkala/search/%D0%BE%D1%80%D1%82%D0%BE%D1%81%D0%B8%D1%82%D0%B8/?ll=47.502815%2C42.973309&sll=47.504748%2C42.983103&sspn=0.246506%2C0.102969&z=14"
        class="btn btn--md btn--blue no-hover"
        target="_blank"
      >
        Найти ближайший магазин
      </a>
    </div>
    <MapComponent :addresses="addresses" />
    <!--    <iframe-->
    <!--      src="https://yandex.ru/map-widget/v1/?um=constructor%3A8f921bbaacd199a95c155050472a3d790524ec5ff0f10c424cf3c8c3346a33a6&amp;source=constructor"-->
    <!--      width="100%"-->
    <!--      height="500"-->
    <!--      frameborder="0"-->
    <!--    ></iframe>-->
  </div>
</template>

<script>
import MapComponent from "components/MapComponent.vue";

export default {
  name: "MapLocationsComponent",
  components: { MapComponent },
  computed: {
    addresses() {
      return this.$store.state.global.branches || [];
    },
  },
};
</script>

<style lang="stylus">
.map {
  &-container {
    position relative
    overflow hidden
    border-radius var(--big-radius)
    height 500px

    &::after {
      position absolute
      content ""
      display block
      top 50%
      transform translate(-45%, -50%)
      width: 824px
      height: 824px
      border-radius 50%
      background var(--white)
      +below(1100px) {
        transform translate(-50%, -50%)
      }
      +below(840px) {
        transform: translate(-30%, 0);
      }
      +below(540px) {
        transform: translate(-30%, 5%);
      }
    }
  }

  &-info {
    position absolute
    left 0
    top 0
    bottom 0
    z-index 1
    padding 66px
    display flex
    flex-direction column
    justify-content center
    align-items flex-start
    gap 15px
    pointer-events none
    +below(1100px) {
      padding 30px
    }
    +below(840px) {
      padding: 15px;
      top: auto;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .btn {
      pointer-events auto
    }
  }

  &-title {
    max-width 382px
    margin 0
  }
}
</style>
