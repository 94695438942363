<template>
  <router-link
    v-if="data"
    class="brands__item"
    :to="{ name: 'catalog', query: { brands_item_id: data.id } }"
    :title="data.title"
  >
    <ImgComponent :img="data.head_img" contain class="brands__item-img" :loader="false" />
  </router-link>
</template>

<script>
export default {
  name: "BrandItemComponent",
  props: {
    data: Object,
  },
};
</script>

<style lang="stylus">
.brands__item {
  width 100%
  border: 1px solid var(--white);
  box-sizing: border-box;
  border-radius: var(--big-radius);
  height 125px
  background var(--white)
  overflow hidden
  display flex
  justify-content center
  align-items center
  //padding 20px
  cursor: pointer;
  padding 18px 34px
  transition var(--transition)
  +below(860px) {
    height 100px
    padding 14px 30px
  }
  +below(540px) {
    height 80px
    padding 8px 24px
  }

  &:hover {
    //background var(--gray)
    border-color var(--main)
  }

  .file-icon {
    width 100%
    height auto
  }

  &-img {
    width 100%
    height 100%
    object-fit contain
    object-position center
  }
}
</style>
