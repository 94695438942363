<template>
  <div class="header-search">
    <div class="header-search__container header-search__input container">
      <InputComponent
        @focus="$store.state._overlays.mobileSearchOverlay = true"
        @input="$emit('search', search)"
        v-model.trim="search"
        icon-position="right"
        type="search"
        placeholder="Я ищу..."
      />
      <SearchResultsDropdownComponent
        v-show="search"
        :items="products"
        :total="total"
        :search="search"
        v-slot="{ item }"
        buttonTitle="Показать все"
        :route="{ name: 'search', query: { title: search } }"
        :loading="loading"
      >
        <ProductCardSearchVersionComponent :data="item" />
      </SearchResultsDropdownComponent>
    </div>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import SearchResultsDropdownComponent from "components/header/components/SearchResultsDropdownComponent.vue";
import ProductCardSearchVersionComponent from "components/product/ProductCardSearchVersionComponent.vue";

export default {
  name: "HeaderSearchComponent",
  props: {
    products: Array,
    total: Number,
    loading: Boolean,
  },
  data() {
    return {
      search: undefined,
    };
  },
  watch: {
    "$route.name"() {
      this.search = null;
    },
    "$route.params"() {
      this.search = null;
    },
  },
  components: { ProductCardSearchVersionComponent, InputComponent, SearchResultsDropdownComponent },
};
</script>

<style lang="stylus">
.header-search {
  padding 10px var(--sides-padding)
  background var(--gray)
  +above(541px) {
    display none
  }

  .input {
    width 100%
    height 40px

    &__container {
      border none
    }
  }
}
</style>
