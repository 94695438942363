<template>
  <div class="default-modal login-modal">
    <div class="default-modal__header default-modal__header--center">
      <button type="button" class="default-modal__back" @click="$emit('close')">
        <IconComponent name="arrow-left-2" />
      </button>
      <h3 class="default-modal__title">Войти по email</h3>
      <button type="button" class="default-modal__close" @click="$emit('closeAll')">
        <IconComponent name="close-square" />
      </button>
    </div>
    <div class="default-modal__body login-modal__body">
      <div class="login-modal__inputs">
        <InputComponent
          v-model="form.email.value"
          :error="form.email.error"
          title="Email"
          placeholder="Электронная почта"
          modified
          type="email"
        />
        <InputComponent
          v-model="form.password.value"
          :error="form.password.error"
          type="password"
          title="Пароль"
          placeholder="Ваш пароль"
          modified
        />
      </div>
    </div>
    <div class="default-modal__footer login-modal__footer">
      <button type="button" class="btn btn--md btn--main" @click="login">
        <IconComponent v-if="loading" name="loading" />
        <template v-else>Войти</template>
      </button>
      <button type="button" class="btn btn--md btn--gray-hollow" @click="showResetPasswordModal">
        Забыли пароль?
      </button>
      <button type="button" class="btn btn--md btn--blue-hollow" @click="showRegisterModal">
        Регистрация
      </button>
    </div>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import IconComponent from "components/IconComponent.vue";
import RegisterModal from "components/modals/components/RegisterModal.vue";
import ResetPasswordModal from "components/modals/components/ResetPasswordModal.vue";
import USER_LOGIN_VIA_EMAIL from "gql/auth/UserLoginViaEmail.graphql";
import axios from "axios";

export default {
  name: "LoginViaEmailModal",
  props: {
    routeAfterLogin: Object,
  },
  data() {
    return {
      number: null,
      loading: false,
      error: null,
      codeError: null,
      form: {
        email: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        password: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
      },
    };
  },
  methods: {
    login() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: USER_LOGIN_VIA_EMAIL,
            variables: {
              email: this.form.email.value,
              password: this.form.password.value,
            },
          })
          .then(({ data }) => {
            if (data && data.UserLoginViaEmail) {
              this.$store.state.auth.user = data.UserLoginViaEmail.user;
              this.$store.state.apollo_token = data.UserLoginViaEmail.token;
              // Сохраняю токен в сессии
              axios({
                method: "post",
                url: "/token",
                data: {
                  token: data.UserLoginViaEmail.token,
                },
                headers: {
                  "Content-Type": "application/json",
                },
              }).then(() => {
                // сохраняем товары в корзине из store, куда они добавлются из localStorage в App.vue
                this.$store
                  .dispatch("CART_ADD_ITEMS", {
                    apollo: this.$apollo,
                    variables: {
                      items: this.$store.state.auth.cart.map((c) => {
                        return {
                          id: c.item.id,
                          count: c.count,
                        };
                      }),
                    },
                  })
                  .then(() => {
                    this.$store.dispatch("ME", {
                      apollo: this.$apollo,
                    });
                  });
                this.loading = false;
                this.$emit("closeAll");
                // this.$router
                //   .push(this.routeAfterLogin || { name: "user" })
                //   .then(() => {
                //     this.loading = false;
                //     this.$emit("close");
                //   })
                //   .catch(() => {
                //     this.loading = false;
                //     this.$emit("close");
                //   });
              });
            }
          })
          .catch(({ graphQLErrors }) => {
            this.loading = false;
            this.parseGqlErrors(graphQLErrors);
          });
      }
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].error = err.extensions.validation[key][0];
            }
          });
        }
      });
    },
    showRegisterModal() {
      this.$store.state._modals.push({
        component: RegisterModal,
        options: {},
      });
    },
    showResetPasswordModal() {
      this.$store.state._modals.push({
        component: ResetPasswordModal,
        options: {},
      });
    },
  },
  components: { IconComponent, InputComponent },
};
</script>

<style lang="stylus">
.login-modal {
  width 100%
  max-width 382px

  &__inputs {
    width 100%
    display grid
    grid-gap 20px

    .input {
      width 100%

      &__container {
        border-color var(--gray-3-dark)
      }

      &__field {
        font-size: 1.125em
        line-height: 20px;
      }
    }
  }

  &__input {
    display grid
    grid-gap 5px
    width 100%
  }

  &__inputs-title {
    display flex
    align-items center
    gap 8px
    width 100%
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    color: var(--body-color-muted);
  }

  &__body > .input {
    width 100%
  }

  &__footer .btn {
    width 100%
    justify-content center
  }
}
</style>
