<template>
  <div class="default-modal login-modal">
    <div class="default-modal__header default-modal__header--center">
      <button type="button" class="default-modal__back" @click="$emit('close')">
        <IconComponent name="arrow-left-2" />
      </button>
      <h3 class="default-modal__title">Забыли пароль?</h3>
      <button type="button" class="default-modal__close" @click="$emit('closeAll')">
        <IconComponent name="close-square" />
      </button>
    </div>
    <div class="default-modal__body login-modal__body">
      <div class="login-modal__inputs">
        <span> Введите свой email — мы отправим на него ссылку для сброса пароля </span>
        <InputComponent
          v-model="form.email.value"
          :error="form.email.error"
          title="Email"
          placeholder="Электронная почта"
          modified
          type="email"
        />
      </div>
    </div>
    <div class="default-modal__footer login-modal__footer">
      <button type="button" class="btn btn--md btn--blue">Отправить</button>
    </div>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import IconComponent from "components/IconComponent.vue";
import RegisterModal from "components/modals/components/RegisterModal.vue";
import USER_PHONE_CONFIRM from "gql/auth/UserPhoneConfirm.graphql";
import USER_LOGIN from "gql/auth/UserLogin.graphql";
import USER_LOGIN_VIA_EMAIL from "gql/auth/UserLoginViaEmail.graphql";
import axios from "axios";

export default {
  name: "ResetPasswordModal",
  props: {
    routeAfterLogin: Object,
  },
  data() {
    return {
      number: null,
      loading: false,
      error: null,
      codeError: null,
      form: {
        email: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        password: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
      },
    };
  },
  methods: {
    login() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: USER_LOGIN_VIA_EMAIL,
            variables: {
              email: this.form.email.value,
              password: this.form.password.value,
            },
          })
          .then(({ data }) => {
            if (data && data.UserLoginViaEmail) {
              this.$store.state.auth.user = data.UserLoginViaEmail.user;
              this.$store.state.apollo_token = data.UserLoginViaEmail.token;
              // Сохраняю токен в сессии

              // сохраняем товары в корзине из store, куда они добавлются из localStorage в App.vue
              this.$store
                .dispatch("CART_ADD_ITEMS", {
                  apollo: this.$apollo,
                  variables: {
                    items: this.$store.state.auth.cart.map((c) => {
                      return {
                        id: c.item.id,
                        count: c.count,
                      };
                    }),
                  },
                })
                .then(() => {
                  this.$store.dispatch("ME", {
                    apollo: this.$apollo,
                  });
                });
              if (this.routeAfterLogin) {
                this.$router
                  .push(this.routeAfterLogin)
                  .then(() => {
                    this.loading = false;
                    this.$emit("close");
                  })
                  .catch(() => {
                    this.loading = false;
                    this.$emit("close");
                  });
              } else {
                this.$emit("close");
              }
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.codeError = "Введен неправильный код подтверждения";
          });
      }
    },
    userLogin() {
      if (!this.loading) {
        if (this.number) {
          this.loading = true;
          this.error = null;
          this.$apollo.provider.defaultClient
            .mutate({
              mutation: USER_LOGIN,
              variables: {
                phone: this.number.replace(/\D+/g, ""),
              },
            })
            .then(() => {
              this.loading = false;
              this.step = 1;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.error = "Введите номер телефона";
        }
      }
    },
    phoneConfirm() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: USER_PHONE_CONFIRM,
            variables: {
              code: parseInt(this.code),
            },
          })
          .then(({ data }) => {
            if (data && data.UserPhoneConfirm) {
              this.$store.state.auth.user = data.UserPhoneConfirm.user;
              this.$store.state.apollo_token = data.UserPhoneConfirm.token;
              // Сохраняю токен в сессии
              axios({
                method: "post",
                url: "/token",
                data: {
                  token: data.UserPhoneConfirm.token,
                },
                headers: {
                  "Content-Type": "application/json",
                },
              }).then(() => {
                // сохраняем товары в корзине из store, куда они добавлются из localStorage в App.vue
                this.$store
                  .dispatch("CART_ADD_ITEMS", {
                    apollo: this.$apollo,
                    variables: {
                      items: this.$store.state.auth.cart.map((c) => {
                        return {
                          id: c.item.id,
                          count: c.count,
                        };
                      }),
                    },
                  })
                  .then(() => {
                    this.$store.dispatch("ME", {
                      apollo: this.$apollo,
                    });
                  });
                this.$router
                  .push(this.routeAfterLogin || { name: "user" })
                  .then(() => {
                    this.loading = false;
                    this.$emit("close");
                  })
                  .catch(() => {
                    this.loading = false;
                    this.$emit("close");
                  });
              });
            }
          })
          .catch(() => {
            this.loading = false;
            this.codeError = "Введен неправильный код подтверждения";
          });
      }
    },
    showRegisterModal() {
      this.$store.state._modals.push({
        component: RegisterModal,
        options: {},
      });
    },
  },
  components: { IconComponent, InputComponent },
};
</script>

<style lang="stylus">
.login-modal {
  width 100%
  max-width 382px

  &__inputs {
    width 100%
    display grid
    grid-gap 20px

    .input {
      width 100%

      &__container {
        border-color var(--gray-3-dark)
      }

      &__field {
        font-size: 1.125em
        line-height: 20px;
      }
    }
  }

  &__input {
    display grid
    grid-gap 5px
    width 100%
  }

  &__inputs-title {
    display flex
    align-items center
    gap 8px
    width 100%
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    color: var(--body-color-muted);
  }

  &__body > .input {
    width 100%
  }

  &__footer .btn {
    width 100%
    justify-content center
  }
}
</style>
